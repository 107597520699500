import {BaseField} from "./basefield.data";

export interface InputState {
  timestamp: number;
  state: InputStateType;
  message?: string;
}

export enum EInputFallbackCategory {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY'
}

export interface InputPlugin {
  active: boolean;
  deprecated: boolean;
  state: InputState;
  name: string;
  type: string;
  note?: string;
  id: string;
  beta: boolean;
  fallbackCategory: EInputFallbackCategory;
  generateExternalId: boolean;
}

export interface InputPluginDetails {
    active: boolean;
  deprecated: boolean;
  state: InputState;
  name: string;
  simpleInputClassName: string;
  pluginData: BaseField[];
  type: string;
  note?: string;
  id: string;
  beta: boolean;
  customDelayTimeForStateChangesInSeconds: number;
  fallbackCategory: EInputFallbackCategory;
  generateExternalId: boolean;
}

export interface SimpleInputPluginInstance {
    name: string;
    simpleInputClassName: string;
    deprecated: boolean;
}

export interface CreateInputPluginInstance {
    name: string;
    simpleInputClassName: string;
}

export interface NewInputPluginStateRequest {
    state: InputPluginStateRequestType
}

export enum InputStateType {
    STOPPED, STARTING, RUNNING, WARNING, ERROR, UNKNOWN
}

export enum InputPluginStateRequestType {
    STOP, START, RESTART
}