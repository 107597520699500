'use strict';

import {ILogService, IRootScopeService, IScope} from "angular";
import { DeletePersonRequest, PendingAlarmGroup, PersonSimple } from "../../../../data/person.data";
import RestService from "../../../../services/rest.service";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import angular = require("angular");

require('./person.tablerow.component.css');

export default class PersonTablerowComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'A',
      this.template = require('./person.tablerow.component.html');
    this.scope = {
      person: '=',
      pending: '=',
      addressbook: '='
    }

    this.controller = PersonTablerowController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class PersonTablerowController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public Notification;
  public $log: ILogService;
  public $uibModal: any;
  public dataService: any;
  public restService: RestService;
  public hasAvailabilityPrivilege: boolean = false;
  public selectedAlarmGroups: any;
  public person: PersonSimple;
  public loading: boolean = false;
  public priv: PrivilegeService;
  public hasPersDelete:boolean= false;
  // bool value to remove option on permission to delete own person entry
  public isOwnPersonEntry:boolean= false;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, $uibModal, dataService, restService: RestService, Notification, privilegeService:PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.dataService = dataService;
    this.restService = restService;
    this.Notification = Notification;
    this.priv = privilegeService;
    this.hasPersDelete = this.priv.has(RolePrivilege.Addressbook_Persons_Delete);
    this.hasAvailabilityPrivilege = this.priv.has(RolePrivilege.Addressbook_OnlineService);
    this.$scope.$watch("ctrl.person",()=>{
      this.isOwnPersonEntry = this.dataService.account.personID === this.person.personID;
      this.$scope.$applyAsync();
    })
  }

  /**
   * Returns true, if this function is excluded or not
   * @param {*} func
   */
  isFunctionExcluded(func: string) {
    if (this.person && this.person.excludedFunctions) {
      return this.person.excludedFunctions.indexOf(func) !== -1;
    }
    return false;
  }

  /**
   * Returns true, if this group is excluded or not
   * @param {*} group
   */
  isGroupExcluded(group: string) {
    if (this.person && this.person.excludedGroups) {
      return this.person.excludedGroups.indexOf(group) !== -1;
    }
    return false;
  }

  /**
   * Delete an existing person from the adressbook
   */
  deletePerson() {
    this.$uibModal.open({
      template: require('../../../modals/misc/save.delete.modal/save.delete.modal.html'),
      controller: 'ConfirmPersonDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        isOldShare: () => {
          return this.person.shared;
        },
        person: () => {
          return this.person;
        },
        okFunction: () => {
          return (deletePersonRequest: DeletePersonRequest) => {
            deletePersonRequest.personId = this.person.personID;
            this.restService.deletePerson(deletePersonRequest).then(() => {
                this.$rootScope.$broadcast('delete.person', this.person);
            }).catch((err) => {
              this.Notification.error({
                message: err.message,
                title: this.person.displayName,
              });
              }
            );
          };
        }
      }
    });
  }

  /**
   * Remove selected function from pending person
   */
  removeFunctionFromPendingPerson(e, fu: string) {
    e.stopPropagation();
    this.person.functions.splice(this.person.functions.indexOf(fu), 1);
  }

  /**
   * Remove selected group from pending person
   */
  removeGroupFromPendingPerson(e, group: string) {
    e.stopPropagation();
    this.person.groups.splice(this.person.groups.indexOf(group), 1);
  }

  /**
   * Remove alarm groups from person
   */
  removeAlarmGroupFromPendingPerson(e, group: PendingAlarmGroup) {
    e.stopPropagation();
    this.person.pendingAlarmGroups.splice(this.person.pendingAlarmGroups.indexOf(group), 1);
  }

  /**
   * Accept or decline pending person
   */
  acceptPending(accept: boolean) {
    this.loading = true;
    let state = accept ? 'ACCEPTED' : 'DECLINED';
    let request = {
      newState: state,
      functions: this.person.functions,
      groups: this.person.groups,
      alarmGroups: this.person.pendingAlarmGroups.map(group => group.id)
    };
    this.dataService.changePersonState(
      this.person, request, (response) => {
        this.loading = false;
      }, (response) => {
        this.loading = false;
        //Error occured
        this.$log.error(response);
      }
    );
  }

  /**
   * Select a person
   */
  selectPerson() {
    if (angular.isUndefined(this.person) || this.person.shared) {
      return;
    }

    this.$uibModal.open({
      template: require('../../../modals/addressbook/edit.person.modal/edit.person.modal.html'),
      controller: 'EditPersonModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        person: this.person,
        openedAsAdmin: false,
        isNew:false
      }
    });
  };

}
