'use strict';
import {SearchObjectResponse, Keyword, ManualAlarmDetails} from "../../../../data/alarm.data";
import {IScope} from "angular";
import {AlarmTemplate} from "../../../../data/template.data";
import * as angular from "angular";
import {is} from "angular-ui-router";
import RestService from "../../../../services/rest.service";
require('./manual.alarm.details.component.scss')
export default class ManualAlarmDetailsComponent{
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'EA'
    this.template = require('./manual.alarm.details.component.html');
    this.scope = {
      template: '=',
      isEditDetails:'=',
      manualAlarmEdit:'='
    };
    this.controller = ManualAlarmDetailsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}
class ManualAlarmDetailsController{
  public $scope: IScope;
  public $translate: any;
  public restService: RestService;
  public manualAlarmEdit: ManualAlarmDetails;

  // modal instance for map view to see/set lat and lng

  public $uibModal:any;
  public isEditDetails = true;
  public template: AlarmTemplate;
  public isGeocoding = false;


  constructor($scope: IScope, $uibModal:any, restService: RestService) {
    this.$scope = $scope
    this.$uibModal = $uibModal;
    this.restService = restService;


    this.$scope.$watch('ctrl.template', (newValue, oldValue, scope) => {
      if (angular.isDefined(oldValue)|| angular.isDefined(newValue)){
        this.setTemplateData();
      }
    });
  }

  /**
   * method to set the data from template to the view for editing. Will only set the details if it is set to edit.
   * @private
   */
  private setTemplateData() {
    //Message
    if (angular.isDefined(this.template.message)) {
      this.manualAlarmEdit.alarmText = this.template.message;
    }
    //Keyword
    if (angular.isDefined(this.template.keyword)) {
      this.manualAlarmEdit.selectedKeyword = this.template.keyword;
    }

    //Description
    if (angular.isDefined(this.template.keyword_description)) {
      this.manualAlarmEdit.selectedKeywordDescription = this.template.keyword_description;
    }
    //Keyword
    if (angular.isDefined(this.template.keyword_misc)) {
      if (angular.isUndefined(this.manualAlarmEdit.keyword)) {
        this.manualAlarmEdit.keyword = {

        } as Keyword;
      }
      this.manualAlarmEdit.keyword.additional = this.template.keyword_misc;
    }
    if (angular.isDefined(this.template.lat) && angular.isDefined(this.template.lng)) {
      this.manualAlarmEdit.lat = this.template.lat;
      this.manualAlarmEdit.lng = this.template.lng;
    }

    //City
    if (angular.isDefined(this.template.city)) {
      this.manualAlarmEdit.selectedCity = this.template.city;
    }
    //PostalCode
    if (angular.isDefined(this.template.postalCode)) {
      this.manualAlarmEdit.selectedPostalCode = this.template.postalCode;
    }
    //Street
    if (angular.isDefined(this.template.street)) {
      this.manualAlarmEdit.selectedStreet = this.template.street;
    }
    //House
    if (angular.isDefined(this.template.house)) {
      this.manualAlarmEdit.selectedHouse = this.template.house
    }
    //Abbreviation
    if (angular.isDefined(this.template.city_abbr)) {
      this.manualAlarmEdit.selectedAbbreviation = this.template.city_abbr;
    }
    this.manualAlarmEdit.keyword = {
      ident: this.template.keyword_ident,
      keyword: this.template.keyword,
      description: this.template.keyword_description
    } as Keyword;
    //Building
    if (angular.isDefined(this.template.building)) {
      this.manualAlarmEdit.building = {
        building: this.template.building,
        lat: this.template.lat,
        lng: this.template.lng,
        additional: this.template.location_additional
      };
      if (angular.isDefined(this.template.building_id)) {
        this.manualAlarmEdit.building.building_id = this.template.building_id;
      }
      this.manualAlarmEdit.selectedBuilding = this.template.building;
    }


  }

  /**
   Get all available buildings
   */
  getBuildings(val: string) {
    return this.restService.getBuildings(val);
  };
  /**
   Get all available cities
   */
  getCities(val: string) {
    return this.restService.getCities(val);
  };

  /**
   Get all available streets
   */
  getStreets(val: string) {
    if (angular.isUndefined(this.manualAlarmEdit.selectedCity) || this.manualAlarmEdit.selectedCity === '') {
      return;
    }

    return this.restService.getStreets(this.manualAlarmEdit.selectedCity, val);
  };

  /**
   A street has been selected
   */
  streetSelected($item) {
    //Set postalcode
    if (!angular.isUndefined($item.postalCode)) {
      this.manualAlarmEdit.selectedPostalCode = $item.postalCode;
    }
    if (!angular.isUndefined($item.abbreviation)) {
      this.manualAlarmEdit.selectedAbbreviation = $item.abbreviation;
    }
    this.manualAlarmEdit.address = $item;

    this.geocode(this.createLocation());
  }

  houseChanged() {
    this.geocode(this.createLocation());
  }

  /**
   A building has been selected
   */
  buildingSelected($item: SearchObjectResponse) {
    //Set postalcode, city and street
    if (!angular.isUndefined($item.postalCode)) {
      this.manualAlarmEdit.selectedPostalCode = $item.postalCode;
    }
    if (!angular.isUndefined($item.street)) {
      this.manualAlarmEdit.selectedStreet = $item.street;
    }
    if (!angular.isUndefined($item.city)) {
      this.manualAlarmEdit.selectedCity = $item.city;
    }
    if (!angular.isUndefined($item.house)) {
      this.manualAlarmEdit.selectedHouse = $item.house;
    }
    if (!angular.isUndefined($item.latitude) && $item.latitude !== -1) {
      this.manualAlarmEdit.lat = $item.latitude;
      this.manualAlarmEdit.lng = $item.longitude;
    }

    this.manualAlarmEdit.building = $item;
  };

  resetSelectedBuilding(){
    if (angular.isUndefined(this.manualAlarmEdit.selectedBuilding) || this.manualAlarmEdit.selectedBuilding==='') {
      if (this.manualAlarmEdit.building) {
        this.manualAlarmEdit.lat = undefined;
        this.manualAlarmEdit.lng = undefined;
        this.manualAlarmEdit.building = {};
      }
    }
  }

  /**
   Get all available keywords
   */
  getKeywords(val: string) {
    return this.restService.getKeywords(val);
  };

  changeKeyword() {
    if (this.manualAlarmEdit.selectedKeyword === '') {
      this.manualAlarmEdit.selectedKeywordDescription = '';
      this.manualAlarmEdit.keyword = undefined;
    }
  };

  /**
   A keyword has been selected
   */
  keywordSelected($item: Keyword) {
    //Set postalcode, city and street
    if (!angular.isUndefined($item.description)) {
      this.manualAlarmEdit.selectedKeywordDescription = $item.description;
    }
    if (!angular.isUndefined($item.keyword)) {
      this.manualAlarmEdit.selectedKeyword = $item.keyword;
    }
    this.manualAlarmEdit.keyword = $item;
  };

  geocode(locationString: string) {
    if (locationString === '' || locationString.length < 5) {
      return;
    }
    this.isGeocoding = true;
    this.restService.geocode(locationString, (response) => {
      this.manualAlarmEdit.lat = response.data.latitude;
      this.manualAlarmEdit.lng = response.data.longitude;
      this.isGeocoding = false;
      this.$scope.$applyAsync();
    }, (error) => {
      this.isGeocoding = false;
    });
  }

  createLocation() {
    let location = '';

    if (this.manualAlarmEdit.selectedStreet) {
      location = this.manualAlarmEdit.selectedStreet;
    }

    if (this.manualAlarmEdit.selectedHouse) {
      location = location + ' ' + this.manualAlarmEdit.selectedHouse;
    }

    if (this.manualAlarmEdit.selectedPostalCode) {
      location = location + ' ' + this.manualAlarmEdit.selectedPostalCode;
    }
    if (this.manualAlarmEdit.selectedCity) {
      location = location + ' ' + this.manualAlarmEdit.selectedCity;
    }

    location = location.trim();
    return location;
  }

  /**
   * Choose alarm on map
   */
  chooseOnMap() {
    this.$uibModal.open({
      template: require('../../../modals/alarms/choose.on.map.modal/choose.on.map.modal.html'),
      controller: 'ChooseAlarmOnMapController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          return {
            lat: this.manualAlarmEdit.lat,
            lng: this.manualAlarmEdit.lng
          }
        },
        position: () => {
          return undefined;
        },
        okFunction: () => {
          return (coords) => {
            this.manualAlarmEdit.lat = coords.lat;
            this.manualAlarmEdit.lng = coords.lng;
          }
        }
      }
    });

  };
}