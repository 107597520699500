import {
  EVehicleStatus,
  EVehicleType,
  IconFrontendRepresentation,
  TacticalSign,
  TacticalSignCategory,
  VehicleIcons
} from './../../../../data/vehicles.data';
import {ILogService, IRootScopeService, IScope} from "angular";
import {ELoginType, UserAccount} from "../../../../data/account.data";
import {Vehicle} from "../../../../data/vehicles.data";
import RestService from '../../../../services/rest.service';
import PrivilegeService from '../../../../services/privilege.service';
import {RolePrivilege} from '../../../../data/privileges.enum';
import HelperService from '../../../../services/helper.service';

'use strict';

require('./vehicle.overview.component.scss');

export default class VehicleOverviewComponent {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      vehicle: '='
    }
    this.template = require('./vehicle.overview.component.html');

    this.controller = VehicleOverviewComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//vehicle
class VehicleOverviewComponentController {
  public status: string[] = ['0','1', '2', '3', '4','5', '6', '7', '8', '9', 'C'];
  public statusSiren: string[];
  public color: string;
  public vehicle: Vehicle;
  public isLoading: boolean = false;
  public isPersonLoggedIn: boolean = false;
  public searchAlternativeVehicle = false;
  public account: UserAccount;
  public hasEditPermission: boolean;
  public tacticalSigns:TacticalSignCategory[];
  public chosenCategory: TacticalSignCategory;
  public iconCategory: VehicleIcons;
  public vehicleIcons:VehicleIcons[];

  constructor(private $scope: IScope,
    private $rootScope: IRootScopeService,
    private $log: ILogService,
    private dataService,
    private restService: RestService,
    private helperService: HelperService,
    public privilegeService: PrivilegeService,
    private $uibModal: any) {

    this.statusSiren = this.helperService.getStatusSiren();
    this.hasEditPermission = this.privilegeService.has(RolePrivilege.Station_Vehicles_Edit) || this.privilegeService.has(RolePrivilege.Station_Sirens_Edit);
    if (this.dataService.hasAccount()){
      this.account = this.dataService.getAccount();
    }
    this.isPersonLoggedIn = (dataService.getAccount() as UserAccount).loginType === ELoginType.PERSON;


    this.restService.getTacticalSigns().then((response)=>{
      this.tacticalSigns = response;
      this.setValueTacticalSign();
    })
    this.restService.getVehicleIcons().then((response)=>{
      this.vehicleIcons = response;
      if (this.vehicle.vehicleIcon){
        this.iconCategory = this.vehicleIcons.find(iconsListed=> iconsListed.categoryName===this.vehicle.vehicleIcon.category);
      }else {
        this.iconCategory = this.vehicleIcons[0];
      }
    })

  }

  getStatusToDisplay(){
   return this.vehicle.status;
  }

  hasEditVehiclesPermission() {
    return this.privilegeService.has(RolePrivilege.Station_Vehicles_Edit);
  }

  hasEditSirensPermission() {
    return this.privilegeService.has(RolePrivilege.Station_Sirens_Edit);
  }
  selectIconCategory(iconCategory: VehicleIcons){
    this.iconCategory = iconCategory;
    this.vehicle.vehicleIcon = this.iconCategory.icons[0];
    this.$scope.$emit("vehicle.iconChange."+this.vehicle.id, this.vehicle.vehicleIcon);
  }

  selectIcon(icon:IconFrontendRepresentation){
    this.vehicle.vehicleIcon= icon;
    this.$scope.$emit("vehicle.iconChange."+this.vehicle.id, icon);
  }

  /**
   * Allow to deselect icon
   */
  clearIcon() {
    this.vehicle.vehicleIcon = undefined;
    this.$scope.$emit("vehicle.iconChange." + this.vehicle.id, undefined);
  }

  /**
   * Allow to deselect tactical icon
   */
  clearTacticalIcon() {
    this.vehicle.tacticalSign = undefined;
  }

  getVehicleIcon(icon: IconFrontendRepresentation) {
    return `/img/static/vehicle_icons/${ encodeURIComponent(icon.category)}/${ encodeURIComponent(icon.filename)}`;
  }

  /**
 * Manually set status
 */
  setStatus(status) {
    this.$log.info('Setting status: ' + status);
    this.isLoading = true;
    this.dataService.updateVehicleStatus(this.vehicle, status, (vehicle: Vehicle) => {
      this.vehicle.status = vehicle.status;
      this.vehicle.statusColor = vehicle.statusColor;
      this.$rootScope.$emit('status.change', this.vehicle);
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    });
  }
  setTacticalSignCategory(category:TacticalSignCategory){
    this.chosenCategory = category;
    this.vehicle.tacticalSign = {nameCategory: this.chosenCategory.name, tacticalSign: this.chosenCategory.tacticalSign[0]};
  }
  setTacticalSign(sign: TacticalSign){
    this.vehicle.tacticalSign = {nameCategory: this.chosenCategory.name, tacticalSign: sign};
  }

  setValueTacticalSign(){
    if (this.vehicle&&this.vehicle.tacticalSign){
      this.chosenCategory = this.tacticalSigns.find(tacticalSignCategory=> tacticalSignCategory.name== this.vehicle.tacticalSign.nameCategory);
    }else {
      this.chosenCategory = this.tacticalSigns[0];
    }
  }

  getTacticalSign(): string {
    if (!this.vehicle || !this.vehicle.tacticalSign ) return '';

    let path = this.vehicle.tacticalSign.tacticalSign.path;
    path = path.replace(/---/, '/');
    const iconPath = `/img/static/taktische_zeichen/${path}`;
    return iconPath;
  }


  setVehicleType(type: EVehicleType) {
    this.vehicle.vehicleType = type;
    if (this.vehicle.vehicleType === EVehicleType.SIREN){
      this.vehicle.status= EVehicleStatus.SIRENE_OK;
    }
    this.$rootScope.$emit('vehicle.type.change.'+this.vehicle.id, this.vehicle);

  }

  hasVehicleError(value: number): boolean {
    if (!this.vehicle) return false;
    if (this.vehicle.height === 0 && this.vehicle.length === 0 && this.vehicle.width === 0) {
      // All values are 0 -> OK
      return false;
    }
    // At least one value is not empty
    if (value === 0) {
      return true;
    }
    return false;
  }

  /**
   * Search for vehicles
   * @param queryString
   */
  getVehicles(queryString: string) {
    return this.restService.searchForVehicles(queryString);
  }

  removeAlternativeVehicle() {
    this.vehicle.alternativeVehicle = undefined;
  }

  alternativeVehicleSelected(vehicle: Vehicle) {
    this.vehicle.alternativeVehicle = {
      vehicleId: vehicle.id,
      name: vehicle.name
    };
  }

  isSiren() {
    return this.vehicle?.vehicleType === EVehicleType.SIREN;
  }

  selectSirenLocationOnMap() {
    if (this.vehicle.vehicleType !== EVehicleType.SIREN) return;

    //let user select coordinates
    this.$uibModal.open({
      template: require('../../../modals/wache/choose.siren.on.map.modal/choose.siren.on.map.modal.html'),
      controller: 'ChooseSirenOnMapController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          if (!this.vehicle.sirenLocation) {
            // Set default values
            this.vehicle.sirenLocation = {
              lat: this.account.settings.lat,
              lng: this.account.settings.lng,
              radius: 0
            };
          }

          const radius = this.vehicle.sirenLocation?.radius ?? 0;
          return {
            lat: this.vehicle.sirenLocation.lat,
            lng: this.vehicle.sirenLocation.lng,
            radius: radius
          }
        },
        position: () => {
          return undefined;
        },
        okFunction: () => {
          return (location) => {
            const radius = location.radius > 0 ? location.radius : null;
            this.vehicle.sirenLocation = {
              lat: location.lat,
              lng: location.lng,
              radius
            };
          }
        }
      }
    });
  }

}