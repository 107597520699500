'use strict';

import { IRootScopeService, IScope } from "angular";
/* @ngInject */
import RestService from "../../../../services/rest.service";
import { ApproveKbaRequest, KbaRequest } from "../../../../data/admin.data";
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";
import { RolePrivilege } from "../../../../data/privileges.enum";

require('./approve.kba.request.modal.scss')

export default class ModalApproveKbaRequestController {
  private $uibModalInstance: any;
  private results: KbaRequest[] = [];
  private ringtone;
  private socketListener;

  public hasApprovalPrivilege = false;
  constructor($uibModalInstance, private $scope: IScope, private Notification, private restService: RestService, private helperService: HelperService, $rootScope: IRootScopeService, private privilegeService: PrivilegeService) {
    this.$uibModalInstance = $uibModalInstance;
    this.helperService.kbaApproveRequestModalOpen = true;

    this.socketListener = $rootScope.$on('new.kba.request', () => {
      this.reload(false);
    });

    try {
      this.ringtone = new Audio(restService.getBaseUrl() + '/files/sounds?name=ringtone_minimal.mp3');
      this.ringtone.play();
    } catch (ex) {
      // Ignore
    }

    this.hasApprovalPrivilege = this.privilegeService.has(RolePrivilege.Admin_KBA_Approve);
    
    this.reload(false);
  }

  reload(closeIfEmpty: boolean) {
    if (closeIfEmpty) {
      this.ringtone.pause();
    }
    this.restService.getAllPendingKbaRequests().then(results => {
      this.results = results;
    }).finally(() => {
      this.$scope.$applyAsync();
      if (closeIfEmpty && this.results.length === 0) {
        this.cancel();
      }
    });
  }

  /**
   * Decline given request
   */
  decline(requestId: string, message?: string) {
    const request = {
      approve: false,
      message: message
    } as ApproveKbaRequest;
    this.restService.approvePendingKbaRequest(requestId, request)
      .catch(errMessage => {
        this.Notification.error({
          message: errMessage
        });
      }).finally(() => {
      this.reload(true);
    });
  }

  /**
   * Approve given request
   */
  approve(requestId: string, message?: string) {
    const request = {
      approve: true,
      message: message
    } as ApproveKbaRequest;
    this.restService.approvePendingKbaRequest(requestId, request).catch(errMessage => {
      this.Notification.error({
        message: errMessage
      });
    }).finally(() => {
      this.reload(true);
    });
  }

  cancel() {
    this.socketListener.uns
    this.helperService.kbaApproveRequestModalOpen = false;
    this.$uibModalInstance.close();
  }
}
