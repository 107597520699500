'use strict';

import {IRootScopeService, IScope} from "angular";
import {KeywordAutoComplete, ObjectAutoComplete} from "../../../../data/search.data";
import {AlarmTemplate} from "../../../../data/template.data";
import {Unit} from "../../../../data/unit.data";
import RestService from "../../../../services/rest.service";
import angular = require("angular");

require('./edit.alarm.template.modal.css');

/* @ngInject */
export default class EditAlarmTemplateModalController {
  public $scope: IScope;
  public $uibModalInstance: any;
  public $translate: any;
  public restService: RestService;
  public template: AlarmTemplate;
  public dataService: any;
  public copyOfModel: AlarmTemplate;
  public $rootScope: IRootScopeService;
  public isNewTemplate = false;
  public selectedKeyword: string;
  public selectedKeywordDescription: string;
  public selectedCity: string;
  public selectedPostalCode: string;
  public selectedStreet: string;
  public selectedHouse: string;
  public selectedBuilding: string;
  public keyword: KeywordAutoComplete;
  public selectedAbbreviation: string;
  public hasLatLng = false;
  public address: any;
  public building: ObjectAutoComplete;
  public isLoading = false;
  public mode: ETemplateMode = ETemplateMode.SETTINGS;

  public isSearchLoading = false;
  public searchFilter: string = '';
  public usersUnits: any;
  public helperService: any;
  public textColor: string;
  public userUnitsSelected: Map<string, number>;
  public unitSearchFilter = {
    name: '',

  };

  constructor($scope: IScope, $rootScope: IRootScopeService, $uibModalInstance, $translate, dataService, helperService, restService: RestService, template: AlarmTemplate) {
    this.$scope = $scope;
    this.dataService = dataService;
    this.$rootScope = $rootScope;
    this.$uibModalInstance = $uibModalInstance;
    this.$translate = $translate;
    this.restService = restService;
    this.template = template;
    this.helperService = helperService;

    this.isNewTemplate = angular.isUndefined(this.template.name);
    this.userUnitsSelected = new Map<string, number>();

    if (!this.isNewTemplate) {
      // Update fields
      this.selectedKeyword = this.template.keyword;
      this.selectedKeywordDescription = this.template.keyword_description;
      this.selectedCity = this.template.city;
      this.selectedPostalCode = this.template.postalCode;
      this.selectedStreet = this.template.street;
      this.selectedHouse = this.template.house;
      this.selectedBuilding = this.template.building;
      this.copyOfModel = angular.copy(this.template);
    }

    if (this.isNewTemplate) {
      this.template = {
        emoji: '',
        color: '#393E46',
        colorFont: '#FFFFFF',
        units: []
      } as AlarmTemplate;

      this.$translate(['EMOJIS.FIRE']).then((tranlsation) => {
        var defaultEmoji = tranlsation['EMOJIS.FIRE'];
        this.template.emoji = defaultEmoji;
      });
    } else {
      if (this.template.editDetailsInTemplateGrid === true) {
        this.template.noConfirm = false;
      }

    }

    this.searchForUnit();
  }

  disableNoConfirm() {
    if (this.template.editDetailsInTemplateGrid === true) {
      // both options are contradicting each other, edit template should always be opened on editing details
      this.template.noConfirm = false;
    }
  }

  changeTemplateColor() {
    // When background changes always set a readable color and ignore what user has set before
    this.template.colorFont = this.helperService.getContrastColor(this.template.color);
  }

  cancel() {
    // Reset data
    if (angular.isDefined(this.copyOfModel)) {
      angular.forEach(this.copyOfModel, (value, key) => {
        this.template[key] = value;
      });
    }
    this.$uibModalInstance.close();
  };

  /**
   * If a unit is selected
   * @param unit
   * @returns
   */
  isSelected(unit: Unit) {
    return this.template.units.filter(u => u.id === unit.id).length > 0;
  }

  /**
   * Select a unit
   * @param unit
   * @param userName
   */
  select(unit: Unit, userName: string) {
    var number = this.nmbOfSelectedBy(userName)
    this.userUnitsSelected.delete(userName)
    if (this.isSelected(unit)) {
      var includedUnit = this.template.units.filter(u => u.id === unit.id)[0];
      this.template.units.splice(this.template.units.indexOf(includedUnit), 1);
      this.userUnitsSelected.set(userName, (number == 0) ? 0 : number - 1)
    } else {
      this.template.units.push(unit);
      this.userUnitsSelected.set(userName, number + 1)
    }
  }

  nmbOfSelectedBy(userName: string) {
    var number = this.userUnitsSelected.get(userName);
    if (number == undefined) {
      number = 0;
    }
    return number;
  }

  // Autocomplete
  /**
      Get all available keywords
  */
  getKeywords(val: string) {
    return this.restService.getKeywords(val);
  }

  /**
    Get all available keywords searched by description
  */
  getKeywordsByDescription(val: string) {
    return this.restService.getKeywordsByDescription(val);
  }

  changeKeyword() {
    if (this.selectedKeyword === '') {
      this.selectedKeywordDescription = '';
    }
  }

  /**
    A keyword has been selected
  */
  keywordSelected(item: KeywordAutoComplete) {
    //Set postalcode, city and street
    if (!angular.isUndefined(item.description)) {
      this.selectedKeywordDescription = item.description;
    }
    if (!angular.isUndefined(item.keyword)) {
      this.selectedKeyword = item.keyword;
    }
    this.keyword = item;
  }

  /**
      Get all available cities
    */
  getCities(val: string) {
    return this.restService.getCities(val);
  }

  /**
    Get all available streets
  */
  getStreets(val: string) {
    if (angular.isUndefined(this.selectedCity) || this.selectedCity === '') {
      return;
    }

    return this.restService.getStreets(this.selectedCity, val);
  }

  /**
    A street has been selected
  */
  streetSelected(item) {
    //Set postalcode
    if (!angular.isUndefined(item.postalCode)) {
      this.selectedPostalCode = item.postalCode;
    }
    if (!angular.isUndefined(item.abbreviation)) {
      this.selectedAbbreviation = item.abbreviation;
    }
    this.hasLatLng = false;
    this.address = item;
  }

  /**
    Get all available buildings
  */
  getBuildings(val: string) {
    return this.restService.getBuildings(val);
  }

  /**
    A building has been selected
  */
  buildingSelected(item: ObjectAutoComplete) {
    //Set postalcode, city and street
    if (!angular.isUndefined(item.postalCode)) {
      this.selectedPostalCode = item.postalCode;
    }
    if (!angular.isUndefined(item.street)) {
      this.selectedStreet = item.street;
    }
    if (!angular.isUndefined(item.city)) {
      this.selectedCity = item.city;
    }
    if (!angular.isUndefined(item.house)) {
      this.selectedHouse = item.house;
    }
    if (!angular.isUndefined(item.abbreviation)) {
      this.selectedAbbreviation = item.abbreviation;
    }
    if (!angular.isUndefined(item.latitude) && item.latitude !== -1) {
      this.hasLatLng = true;
    } else {
      this.hasLatLng = false;
    }

    this.building = item;
  }



  ok() {
    if (angular.isUndefined(this.template.name) || this.template.name === '') {
      return;
    }

    this.isLoading = true;
    var data = this.template;
    //Keyword
    if (angular.isDefined(this.selectedKeyword)) {
      data.keyword = this.selectedKeyword;
    }
    //Keyword
    if (angular.isDefined(this.keyword)) {
      data.keyword_misc = this.keyword.additonal;
      data.keyword_ident = this.keyword.ident;
    }
    //Description
    if (angular.isDefined(this.selectedKeyword)) {
      data.keyword_description = this.selectedKeywordDescription;
    }
    //Street
    if (angular.isDefined(this.selectedStreet)) {
      data.street = this.selectedStreet;
    }
    //House
    if (angular.isDefined(this.selectedHouse)) {
      data.house = this.selectedHouse;
    }
    //City
    if (angular.isDefined(this.selectedCity)) {
      data.city = this.selectedCity;
    }
    //PostalCode
    if (angular.isDefined(this.selectedPostalCode)) {
      data.postalCode = this.selectedPostalCode;
    }
    //Abbreviation
    if (angular.isDefined(this.selectedAbbreviation)) {
      data.city_abbr = this.selectedAbbreviation;
    }

    if (angular.isDefined(this.selectedBuilding)) {
      data.building = this.selectedBuilding;
    }

    //Building
    if (angular.isDefined(this.building)) {
      data.building = this.building.building;
      if (this.building.latitude !== -1) {
        data.lat = this.building.latitude;
        data.lng = this.building.longitude;
      }
      if (angular.isDefined(this.building.additional)) {
        data.location_additional = this.building.additional;
      }
      data.building_id = this.building.id;
    }

    this.isLoading = true;
    if (this.isNewTemplate) {
      // Is a new template and add it
      this.dataService.addAlarmTemplate(data, () => {
        this.isLoading = false;
        this.isNewTemplate = false;
        this.$rootScope.$emit('alarm.template.refresh');
        this.$uibModalInstance.close();
      }, (err) => {
        this.isLoading = false;
      });
    } else {
      // Update existing template
      this.dataService.updateAlarmTemplate(data, () => {
        this.isLoading = false;
        this.$rootScope.$emit('alarm.template.refresh');
        this.$uibModalInstance.close();
      }, (err) => {
        this.isLoading = false;
      });
    }
  }

  /**
 * Search and load all units for all users (if available)
 */
  searchForUnit() {
    return new Promise<void>((resolve, reject) => {
      this.isSearchLoading = true;
      this.restService.searchForUnits(this.searchFilter, false).then(result => {
        this.usersUnits = result.users;
      }).finally(() => {
        this.isSearchLoading = false;
        this.$scope.$applyAsync();
        resolve();
      });
    });
  }

  /**
   * Reset search
   */
  resetSearchAndReload() {
    this.searchFilter = '';
    this.searchForUnit();
  }
}

enum ETemplateMode {
  SETTINGS = 'SETTINGS',
  UNITS = 'UNITS'
}